
import MarketInquiryForm from '@/components/marketInquiry/MarketInquiryForm.vue'
import MarketInquiryReceipt from '@/components/marketInquiry/MarketInquiryReceipt.vue'
import Spinner from '@/components/Spinner.vue'
import { HOST } from '@/globals/javascript/_utils/host'
import { appStore, currentStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MarketInquiryFlow',
  props: {
    productDetails: {
      type: Object as PropType<{
        title: string
        url: string
        imageURL: string
      }>,
      required: true,
    },
  },
  data() {
    return {
      states: {
        success: false,
        error: false,
        showReceipt: false,
        isLoading: false,
      },
      params: {
        name: '',
        email: '',
        phone: '',
        desiredQuantity: '',
        desiredPrice: '',
        message: '',
        delivery: [] as string[],
      },
      missingInputs: [] as HTMLElement[],
    }
  },
  computed: {
    ...mapState(currentStore, ['project', 'projectAddress']),
    projectDetails() {
      const url =
        window.location.origin + this.$router.resolve({ name: 'Overview' }).href

      return {
        id: this.project?.id ?? '',
        address: this.projectAddress,
        url,
      }
    },
  },
  watch: {
    params: {
      handler() {
        this.$nextTick(() => {
          this.removeHighlightFromFulfilledInputs()
        })
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(appStore, ['setShownDialog']),
    onGoBack() {
      this.states.showReceipt = false
      this.states.error = false
    },
    async onSubmit() {
      this.checkMissingRequiredInputs()

      if (this.missingInputs.length) {
        this.highlightMissingRequiredData()

        return
      }

      // Init spinner
      this.states.isLoading = true

      const {
        name,
        email,
        phone,
        delivery,
        desiredQuantity,
        desiredPrice,
        message,
      } = this.params

      const recipient = currentStore().project?.data.market.contactEmail ?? ''

      // Execute fetch
      const response = await fetch(
        `${HOST.cloudFunction}/online_shared_marketInquiryAPI/inquiries_gen2`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            recipientEmail: recipient,
            contactDetails: {
              name,
              email,
              phone,
            },
            productDetails: this.productDetails,
            projectDetails: this.projectDetails,
            deliveryOptions: {
              selfPickup: delivery.includes('self-pickup'),
              selfDismantling: delivery.includes('self-dismantling'),
              toBeDelivered: delivery.includes('want-delivery'),
            },
            desiredQuantity,
            desiredPrice,
            message,
          }),
        }
      )

      // Prep correct receipt
      const requestDelievered = response.status === 201

      this.states.success = requestDelievered
      this.states.error = !requestDelievered

      // Show receipt
      this.states.showReceipt = true
      this.states.isLoading = false
    },
    checkMissingRequiredInputs() {
      const requiredInputs = [
        ...this.$el.querySelectorAll('.Required'),
      ] as HTMLElement[]

      this.missingInputs = requiredInputs.filter((el) => {
        const inputs = [
          ...el.querySelectorAll('input, textarea'),
        ] as HTMLInputElement[]

        // Check if input are missing data
        return inputs.every((el) => {
          if (el.type === 'checkbox') {
            return !el.checked
          }

          return !el.value
        })
      })
    },
    highlightMissingRequiredData() {
      this.missingInputs.forEach((el) => el.classList.add('Error'))
    },
    removeHighlightFromFulfilledInputs() {
      this.missingInputs.forEach((el) => {
        const inputs = [
          ...el.querySelectorAll('input, textarea'),
        ] as HTMLInputElement[]

        const inputsCleared = inputs.some((x) => {
          if (x.type === 'checkbox') {
            return x.checked
          }

          return !!x.value
        })

        if (inputsCleared) {
          el.classList.remove('Error')
        }
      })
    },
  },
  components: { Spinner, MarketInquiryForm, MarketInquiryReceipt },
})
