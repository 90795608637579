import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3391d08e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MarketInquiryFlow" }
const _hoisted_2 = {
  key: 0,
  class: "MarketInquirySpinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_MarketInquiryReceipt = _resolveComponent("MarketInquiryReceipt")!
  const _component_MarketInquiryForm = _resolveComponent("MarketInquiryForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.states.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Spinner, { size: "large" })
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.states.showReceipt)
            ? (_openBlock(), _createBlock(_component_MarketInquiryReceipt, {
                key: 0,
                success: _ctx.states.success,
                error: _ctx.states.error
              }, null, 8, ["success", "error"]))
            : (_openBlock(), _createBlock(_component_MarketInquiryForm, {
                key: 1,
                name: _ctx.params.name,
                "onUpdate:name": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.name) = $event)),
                email: _ctx.params.email,
                "onUpdate:email": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.email) = $event)),
                phone: _ctx.params.phone,
                "onUpdate:phone": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.phone) = $event)),
                desiredQuantity: _ctx.params.desiredQuantity,
                "onUpdate:desiredQuantity": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.desiredQuantity) = $event)),
                desiredPrice: _ctx.params.desiredPrice,
                "onUpdate:desiredPrice": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.params.desiredPrice) = $event)),
                message: _ctx.params.message,
                "onUpdate:message": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.params.message) = $event)),
                delivery: _ctx.params.delivery,
                "onUpdate:delivery": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.params.delivery) = $event))
              }, null, 8, ["name", "email", "phone", "desiredQuantity", "desiredPrice", "message", "delivery"])),
          (!_ctx.states.showReceipt)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "MarketInquirySubmit",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
              }, _toDisplayString(_ctx.mixWB('SEND')), 1))
            : _createCommentVNode("", true),
          (_ctx.states.showReceipt && _ctx.states.success)
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "MarketInquirySubmit",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setShownDialog()))
              }, _toDisplayString(_ctx.mixWB('CLOSE')), 1))
            : _createCommentVNode("", true),
          (_ctx.states.showReceipt && _ctx.states.error)
            ? (_openBlock(), _createElementBlock("button", {
                key: 4,
                class: "MarketInquirySubmit",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onGoBack && _ctx.onGoBack(...args)))
              }, _toDisplayString(_ctx.mixWB('GO_BACK')), 1))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}